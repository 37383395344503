<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    append-to-body
    :modal="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="60%"
  >
    <div>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-divider></el-divider>
      <el-row class="button-group">
        <el-button type="primary" size="small" plain icon="el-icon-remove" @click="handleAdd">新增</el-button>
      </el-row>
      <el-table
        :data="tableData"
        style="min-height:400px;"
        v-loading="loading"
        stripe
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" label width="50"></el-table-column>
        <el-table-column prop="personName" label="老师名" width="280"></el-table-column>
        <el-table-column prop="isClassTeacher" label="是否班主任" width="150">
          <template slot-scope="{row}">{{row.isClassTeacher ? "是" : "否"}}</template>
        </el-table-column>
        <el-table-column prop="position" label="学科" width="120"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="{row}">
            <el-row>
              <el-col>
                <el-link type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>-
                <el-link type="danger" :underline="false" @click="handleDelete(row)">删除</el-link>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total,  prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <companyInfo-teacher-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="title"
      :companyId="teacherCompanyId"
      :parentCompanyId="teacherParentCompanyId"
      :companyResult="companyResult"
      @close="onDetailModalClose"
    ></companyInfo-teacher-detail>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import CompanyInfoTeacherDetail from "./companyInfo-teacher-detail";
import personCompanyApi from "@/api/base/personCompany";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: [
    "teacherCompanyId",
    "teacherParentCompanyId",
    "title",
    "companyResult",
  ],
  data() {
    var self = this;

    return {
      queryModel: {
        keyPersonId: "",
        associatedPersonId: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showDialog: true,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("personId", "");
      formData.append("companyId", self.teacherCompanyId);
      personCompanyApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;
          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleAdd() {
      this.modalTitle = self.company;
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;
      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.loading = true;

          var formData = new FormData();
          formData.append("id", record.id);
          formData.append("position", record.position);

          personCompanyApi.remove(formData).then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "companyInfo-teacher-detail": CompanyInfoTeacherDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>