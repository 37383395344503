<template>
  <div style="padding-left:5px;">
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/companyInfo">单位管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="单位名称" prop="name">
        <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
      </el-form-item>
      <el-form-item label="单位类别" prop="level">
        <el-select
          v-model="queryModel.type"
          size="mini"
          filterable
          clearable
          placeholder="请选择"
          style="width:150px"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-form-item label="上级单位" prop="parentId">
          <!-- <el-select
          size="mini"
          v-model="queryModel.parentId"
          filterable
          placeholder="请选择"
          style="width:280px"
        >
          <el-option
            v-for="company in companyResult"
            :key="company.id"
            :label="company.name"
            :value="company.id"
          ></el-option>
          </el-select>-->
          <el-select-tree
            size="mini"
            :props="props"
            :options="companyResult"
            v-model="queryModel.parentId"
            height="200"
          ></el-select-tree>&nbsp;
          <el-checkbox v-model="queryModel.subordinate">是否包含下级单位</el-checkbox>
        </el-form-item>
        <el-form-item label="开启健康打卡提醒" prop="healthyReportNoticeEnable">
          <el-select
            v-model="queryModel.healthyReportNoticeEnable"
            size="mini"
            filterable
            clearable
            placeholder="请选择"
            style="width:150px"
          >
            <el-option label="已开启" value="1"></el-option>
            <el-option label="未开启" value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-upload2"
        @click="batchImportVisible = true"
      >导入</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-edit-outline"
        :disabled="multipleSelection.length==0"
        @click="handleUpgradeClass()"
      >班级调整</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-edit-outline"
        :disabled="multipleSelection.length==0"
        @click="handleGraduateClass()"
      >班级毕业</el-button>
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      stripe
      :height="tableHeight"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="sortNo" label="序号" width="120"></el-table-column>
      <el-table-column prop="name" label="单位名称" width="230"></el-table-column>
      <el-table-column prop="shortName" label="简称" width="120"></el-table-column>
      <el-table-column prop="parentName" label="上级单位" width="230"></el-table-column>
      <el-table-column prop="typeN" label="性质" width="80"></el-table-column>
      <el-table-column prop="identifier" label="班级码" width="80">
        <template slot-scope="{row}">
          <span v-if="row.type == '3'">{{row.identifier}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" sort-by="remark_" width="350" label="备注"></el-table-column>
      <el-table-column prop="healthyReportNoticeEnable" width="100" label="健康打开提醒"></el-table-column>
      <el-table-column prop="healthyReportNoticeTime" width="100" label="健康打开提醒时间"></el-table-column>
      <el-table-column label="操作" width="250" fixed="right">
        <template slot-scope="{row}">
          <el-row>
            <el-col>
              <el-link type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>-
              <el-link type="danger" :underline="false" @click="handleDelete(row)">删除</el-link>-
              <!-- <el-link type="primary" :underline="false" @click="bindDevice(row)">关联设备</el-link>- -->
              <el-link type="primary" :underline="false" @click="bindClassTeacher(row)">关联班主任</el-link>-
              <el-link type="primary" :underline="false" @click="bindTimeTemplate(row)">关联时间模板</el-link>
            </el-col>
            <el-col>
              <el-popover
                placement="left"
                width="300"
                trigger="click"
                @show="openQRCode(row)"
                @hide="closeQRCode(row)"
              >
                <div :ref="'qrcode_' + row.id" style="width:300px;height:300px;"></div>
                <el-link type="primary" :underline="false" slot="reference">健康公示二维码</el-link>
              </el-popover>-
              <span v-if="row.type != null && row.type != ''">
                <el-link type="primary" :underline="false" @click="openDP(row)">大屏网址</el-link>
              </span>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <companyInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></companyInfo-detail>
    <companyDeviceRelation-list
      v-if="showDeviceModal"
      :companyId="companyId"
      @close="onDetailModalClose"
    ></companyDeviceRelation-list>
    <el-dialog
      title="导入"
      :visible.sync="batchImportVisible"
      :modal-append-to-body="false"
      style="text-align: left;"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px">
        <el-form-item label="模板下载">
          <el-link
            href="http://rccs.oss-cn-hangzhou.aliyuncs.com/smart/companyInfo/2020/5/07114955253.xls"
            type="primary"
            target="_blank"
          >点击下载模板</el-link>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            accept=".xls"
            :action="uploadUrlXls"
            :data="uploadXlsData"
            :headers="headers"
            :on-preview="handleBatchImportPreview"
            name="uploadFile"
            :multiple="true"
            :limit="1"
            :on-remove="handleBatchImportRemove"
            :before-remove="beforeBatchImportRemove"
            :before-upload="beforeUpload"
            :on-exceed="handleBatchImportExceed"
            :on-success="handleBatchImportSuccess"
            :file-list="batchImportFileList"
          >
            <el-button size="small" type="primary" :loading="xlsLoading">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xls文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="batchImportVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!--批量导入E-->
    <upgrade-class
      :data="multipleSelection"
      :parentId="queryModel.parentId"
      :visible.sync="upgradeClassVisible"
      :companyResult="companyResult"
      @success="changePage(1)"
    ></upgrade-class>
    <graduate-class
      :data="multipleSelection"
      :visible.sync="graduateClassVisible"
      @success="changePage(1)"
    ></graduate-class>
    <!-- 班级查看 start-->
    <companyInfo-teacher-list
      v-if="showPersonCompanyModal"
      :teacherCompanyId="teacherCompanyId"
      :teacherParentCompanyId="teacherParentCompanyId"
      :companyResult="companyResult"
      :title="teacherCompanyName"
      @close="onDetailModalClose"
    ></companyInfo-teacher-list>
    <!-- 班级查看 end-->
    <!--时间模板-->
    <deviceInfo-time-template
      v-if="showTimeTemplate"
      :companyId="companyId"
      @close="onTimeTemplateClose"
    ></deviceInfo-time-template>
  </div>
</template>
<script>
import Constant from "@/constant";
import companyInfoDetail from "./companyInfo-detail";
import companyDeviceRelationList from "./companyDeviceRelation-list";
import companyInfoTeacherList from "./companyInfo-teacher-list";
import deviceInfoTimeTemplate from "./deviceInfo-time-template";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import UpgradeClass from "@/components/UpgradeClass";
import GraduateClass from "@/components/GraduateClass";
import QRCode from "qrcodejs2";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import pageUtil from "@/utils/page";
import { getToken } from "@/utils/auth"; // get token from cookie
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseCompanyInfoList",
  data() {
    var self = this;
    return {
      activeName: "list",
      queryModel: {
        name: "",
        parentId: "",
        subordinate: false,
        type: "",
        healthyReportNoticeEnable: ""
      },
      loading: false,
      tableData: [],
      treeData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      tableHeight: 400,
      showDeviceModal: false,
      showTimeTemplate: false,
      companyId: "",
      qrCode: "",
      companyResult: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      batchImportVisible: false,
      batchImportFileList: [],
      uploadUrlXls: Constant.serverUrl + "/base/companyInfo/importXls",
      uploadXlsData: {
        subFolder: "company",
        companyId: "",
      },
      headers: {
        Authorization: getToken(),
      },
      xlsLoading: false,
      typeList: [],
      upgradeClassVisible: false,
      graduateClassVisible: false,
      showPersonCompanyModal: false,
      teacherCompanyId: "",
      teacherCompanyName:"",
      teacherParentCompanyId:""
    };
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    dataDictionaryApi
      .findByCatalogName({ catalogName: "单位性质" })
      .then((response) => {
        var jsonData = response.data;
        this.typeList = jsonData.data;
      });

    this.changePage(1);
    this.loadTree();

    setTimeout(() => {
      console.log(self.$refs.formTable.$el.offsetTop);

      //页面高度-列表上面的高度-header高度-分页控件高度
      self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
    }, 1000);
  },
  methods: {
    loadTree() {
      var formData = new FormData();
      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if (self.queryModel.name != null) {
        formData.append("name", self.queryModel.name);
      }

      if (self.queryModel.parentId != null) {
        formData.append("parentId", self.queryModel.parentId);
      }

      if (self.queryModel.type != null) {
        formData.append("type", self.queryModel.type);
      }

      if (self.queryModel.healthyReportNoticeEnable != null) {
        formData.append("healthyReportNoticeEnable", self.queryModel.healthyReportNoticeEnable);
      }

      formData.append("subordinate", self.queryModel.subordinate);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      companyInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.changePage(1);
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          companyInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        companyInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showDeviceModal = false;
      this.showPersonCompanyModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onTimeTemplateClose(refreshed) {
      //保存成功后回调
      this.showTimeTemplate = false;
    },
    bindTimeTemplate(record) {
      //关联时间模板
      this.companyId = record.id;
      this.showTimeTemplate = true;
    },
    bindDevice(record) {
      //关联设备
      this.companyId = record.id;
      this.showDeviceModal = true;
    },
    openQRCode(record) {
      //el.innerHTML = "";
      var self = this;
      self.$refs["qrcode_" + record.id].innerHTML = "";
      //默认企业版
      var qrtext =
        process.env.VUE_APP_COMPANY_HEALTH_WECHAT_QY_URL +
        "?companyId=" +
        record.id;
      var type = record.type;
      if ("1" == type) {
        //企业版
        qrtext =
          process.env.VUE_APP_COMPANY_HEALTH_WECHAT_QY_URL +
          "?companyId=" +
          record.id;
      } else if ("2" == type) {
        //校园版
        qrtext =
          process.env.VUE_APP_COMPANY_HEALTH_WECHAT_XY_URL +
          "?companyId=" +
          record.id;
      }

      var el = self.$refs["qrcode_" + record.id];

      var qr = new QRCode(el, {
        text: qrtext,
        width: 300,
        height: 300,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    closeQRCode(record) {
      self.$refs["qrcode_" + record.id].innerHTML = "";
    },
    openDP(record) {
      var companyId = record.id;
      var type = record.type;
      var dpUrl = record.wallUrl;
      if (dpUrl == "" || dpUrl == null) {
        if ("1" == type) {
          //企业版
          dpUrl =
            process.env.VUE_APP_COMPANY_HEALTH_QY_URL +
            "?companyId=" +
            companyId;
        } else if ("2" == type) {
          //校园版
          dpUrl =
            process.env.VUE_APP_COMPANY_HEALTH_XY_URL +
            "?companyId=" +
            companyId;
        } else {
          //企业版
          dpUrl =
            process.env.VUE_APP_COMPANY_HEALTH_QY_URL +
            "?companyId=" +
            companyId;
        }
      } else {
        //填写了固定地址wallUrl
        dpUrl = dpUrl + "?companyId=" + companyId;
      }
      window.open(dpUrl);
    },
    beforeUpload(file, fileList) {
      //导入前判断
    },
    //批量导入-上传成功
    handleBatchImportSuccess(response, file, fileList) {
      var self = this;
      self.xlsLoading = false;
      if (response.result) {
        self.$message.success(response.message);
        this.batchImportFileList = [];
        this.changePage(1);
        this.uploadCompanyId = "";
        this.uploadXlsData.companyId = "";
        this.batchImportVisible = false;
      } else {
        //this.$message.error(response.message);
        this.batchImportFileList = [];
        this.changePage(1);
        this.uploadCompanyId = "";
        this.uploadXlsData.companyId = "";

        if (response.data != null) {
          //下载有错误信息提示的报表
          //window.open(response.data);
          self.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message:
              response.message +
              `,<a href="${response.data}" target="_blank">点击下载未导入的数据报表</a>&nbsp;`,
            duration: 30000,
          });
        }
      }
    },
    //批量导入-预览
    handleBatchImportPreview(file) {
      console.log(file.url);
    },
    //批量导入-移除
    handleBatchImportRemove(file, fileList) {
      console.log(file, fileList);
    },
    //批量导入-移除前操作
    beforeBatchImportRemove(file, fileList) {
      console.log(file, fileList);
    },
    //批量导入-文件超出个数限制时的钩子
    handleBatchImportExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    handleUpgradeClass() {
      var filterItems = this.multipleSelection.filter(
        (item) => item.typeN != "班级"
      );

      console.log(this.multipleSelection);

      if (filterItems.length > 0) {
        this.$message.warning("选项中有非班级类型!");
        return;
      } else {
        this.upgradeClassVisible = true;
      }
    },
    handleGraduateClass() {
      var filterItems = this.multipleSelection.filter((item) => {
        item.typeN != "班级";
      });

      if (filterItems.length > 0) {
        this.$message.warning("选项中有非班级类型!");
        return;
      } else {
        this.graduateClassVisible = true;
      }
    },
    bindClassTeacher(record) {
      this.showPersonCompanyModal = true;
      this.teacherCompanyName = record.name;
      this.teacherCompanyId = record.id;
      this.teacherParentCompanyId = record.parentId;
    },
  },
  mounted: function () {},
  components: {
    "companyInfo-detail": companyInfoDetail,
    "companyDeviceRelation-list": companyDeviceRelationList,
    "el-select-tree": SelectTree,
    "upgrade-class": UpgradeClass,
    "graduate-class": GraduateClass,
    "companyInfo-teacher-list": companyInfoTeacherList,
    "deviceInfo-time-template": deviceInfoTimeTemplate
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>