
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'120px'">
        <el-row>
          <el-col :span="12">
            <el-form-item label="单位名称" prop="name">
              <el-input v-model="formModel.name" placeholder="请输入单位名称" style="width:100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="序号" prop="sortNo">
              <el-input v-model="formModel.sortNo" placeholder="请输入序号" style="width:100%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="简称" prop="shortName">
              <el-input v-model="formModel.shortName" placeholder="请输入单位名称" style="width:100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="安全热线" prop="securityHotline">
              <el-input v-model="formModel.securityHotline" placeholder="请输入安全热线" style="width:100%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上级单位" prop="parentId">
              <!-- <el-select
                v-model="formModel.parentId"
                filterable
                placeholder="请选择"
                style="width:90%"
              >
                <el-option
                  v-for="company in companyResult"
                  :key="company.id"
                  :label="company.name"
                  :value="company.id"
                ></el-option>
              </el-select>-->
              <el-select-tree
                :props="props"
                :options="companyResult"
                v-model="formModel.parentId"
                size="mediumn"
                width="100%"
              ></el-select-tree>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位性质" prop="type">
              <el-select v-model="formModel.type" filterable placeholder="请选择" style="width:100%">
                <el-option
                  v-for="result in typeData"
                  :key="result.id"
                  :label="result.name"
                  :value="result.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="formModel.remark"
                style="width:100%;"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="识别码" prop="identifier">
              <el-input
                v-model="formModel.identifier"
                placeholder=""
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="formModel.type=='2'">
          <el-col :span="12">
            <el-form-item label="健康打卡提醒" prop="healthyReportNoticeEnable">
              <el-switch v-model="formModel.healthyReportNoticeEnable"
                active-color="#13ce66"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="formModel.type=='2'">
          <el-col :span="12">
            <el-form-item label="打卡提醒时间" prop="healthyReportNoticeTime">
              <el-time-picker
                v-model="formModel.healthyReportNoticeTime"
                class="date-box"
                format="HH:mm"
                value-format="HH:mm"
              ></el-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通知打卡时间" prop="healthyReportSubmitTime">
              <el-time-picker
                v-model="formModel.healthyReportSubmitTime"
                class="date-box"
                format="HH:mm"
                value-format="HH:mm"
              ></el-time-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="大屏网址" prop="wallUrl">
              <el-input v-model="formModel.wallUrl" placeholder="请输入" style="width:100%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="欢迎语开关" prop="welcomeColor">
              <el-switch
                v-model="formModel.welcomeEnable"
                active-color="#13ce66"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-form-item>
          </el-col>          
          <el-col :span="12">
            <el-form-item label="欢迎语颜色" prop="welcomeColor">
              <el-color-picker v-model="formModel.welcomeColor"></el-color-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="大屏欢迎语" prop="welcomeText">
              <el-input
                v-model="formModel.welcomeText"
                placeholder="请输入"
                style="width: 100%"
                :rows="3"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="允许家长上传照片开关" prop="uploadParentPhotosEnable">
              <el-switch
                v-model="formModel.uploadParentPhotosEnable"
                active-color="#13ce66"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-form-item>
          </el-col>          
          <el-col :span="12">
            <el-form-item label="语音通知开关" prop="voiceNoticeEnable">
              <el-switch
                v-model="formModel.voiceNoticeEnable"
                active-color="#13ce66"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-form-item>
          </el-col>  
        </el-row>
        <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="一级位置" prop="position1Name">
              <el-input v-model="formModel.position1Name" placeholder="请输入一级位置" style="width:80%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="二级位置" prop="position2Name">
              <el-input v-model="formModel.position2Name" placeholder="请输入二级位置" style="width:80%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="三级位置" prop="position3Name">
              <el-input v-model="formModel.position3Name" placeholder="请输入三级位置" style="width:80%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="四级位置" prop="position4Name">
              <el-input v-model="formModel.position4Name" placeholder="请输入四级位置" style="width:80%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="五级位置" prop="position5Name">
              <el-input v-model="formModel.position5Name" placeholder="请输入五级位置" style="width:80%"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="24">
            <el-form-item label="单位logo" prop="logo">
              <el-upload
                class="avatar-uploader"
                name="photoFile"
                :action="uploadUrl"
                :show-file-list="false"
                :headers="headers"
                :data="uploadData"
                :on-success="handleAvatarSuccess"
              >
                <img v-if="fileUrl" :src="fileUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import companyInfoApi from "@/api/base/companyInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import SelectTree from "@/components/SelectTree";

import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        name: [
          { required: true, message: "单位名称不能为空", trigger: "blur" }
        ],
        type: [{ required: true, message: "性质不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      //上传地址
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      uploadData: {
        subFolder: "companyInfo"
      },
      fileUrl: "",
      headers: {
        Authorization: getToken()
      },
      companyResult: [],
      typeData: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    var formData = new FormData();
    formData.append("catalogName", "单位性质");
    dataDictionaryApi.findByCatalogName(formData).then(response => {
      var jsonData = response.data;
      this.typeData = jsonData.data;
    });

    this.loadTree();
  },
  methods: {
    loadTree() {
      var formData = new FormData();
      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (self.formModel.parentId == null) {
              self.formModel.parentId = "";
            }

            if (id == null || id.length == 0) {
              return companyInfoApi.add(self.formModel);
            } else {
              return companyInfoApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      var self = this;
      self.formModel.logo = res.data;
      self.fileUrl =
        res.data + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    }
  },
  mounted: function() {
    var self = this;
    (function() {
      if (self.businessKey.length == 0) {
        return companyInfoApi.create();
      } else {
        return companyInfoApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          let logo = self.formModel.logo;
          if (logo != null) {
            self.fileUrl =
              logo + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>