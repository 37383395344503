<template>
    <el-dialog
        title="班级毕业"
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="80%"
        @close="close"
    >
        <el-form ref="queryForm" :model="formModel" inline class="demo-form-inline">
            <el-form-item label="班级毕业届">
                <el-input
                    size="mini"
                    placeholder="班级毕业届"
                    v-model="formModel.graduation">
                </el-input>
            </el-form-item>
            <el-form-item label="班级列号">
                <el-input
                    size="mini"
                    placeholder="起始列号,结束列号"
                    v-model="formModel.classColRange">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="preview"
                >预览</el-button>
            </el-form-item>            
            <el-table
                ref="formTable"
                :data="formModel.classList"
                stripe
                >
                <el-table-column prop="srcShortName" label="班级简称" width="200"></el-table-column>
                <el-table-column prop="srcName" label="班级全称" width="200"></el-table-column>
                <el-table-column prop="parentName" label="上级单位" width="200"></el-table-column>
                <el-table-column prop="graduationName" label="毕业班级全称" width="300">
                    <template slot-scope="scope">
                        <el-form-item
                        :prop="'classList.' + scope.$index + '.graduationName'"
                        :rules="ruleValidate['graduationName']"
                        >
                            <el-input
                                placeholder="毕业班级全称"
                                v-model="scope.row.graduationName">
                            </el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>
        <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="handleGraduateClass" :loading="loading">确定</el-button>
      </div>
    </el-dialog>
</template>
<script>
import companyInfoApi from "@/api/base/companyInfo";

export default {
    props: {
        "data" : {            
            type: Array
        },
        "visible" :{
            type: Boolean
        }
    },
    watch: {
        "data":function(newVal,oldVal){
            this.formModel.classList = newVal.map((item)=>{
                return {
                    id: item.id,
                    srcShortName: item.shortName,
                    srcName : item.name,
                    parentName: item.parentName,
                    graduationName: ""
                }
            });
        },
        "visible":function(newVal,oldVal){
            this.dialogVisible = newVal;
        }
    },
    data() {
        return {
            ruleValidate: {
                graduationName: [{ required: true, message: "名称不能为空", trigger: "blur" }]
            },
            dialogVisible: false,
            loading: false,
            formModel: {
                classColRange: "",
                graduation: "",
                classList: []
            },
        }
    },
    methods:{
        close() {
            this.$emit('update:visible', false);
        },
        preview() {
            var classColRange = this.formModel.classColRange.split(",");

            this.formModel.classList.forEach(item=>{
                var classColStartIndex = parseInt(classColRange[0])-1;
                var classColEndIndex = classColStartIndex;

                if(classColRange.length>1){
                    classColEndIndex = parseInt(classColRange[1])-1;
                }

                var srcShortName = item.srcShortName;
                var begin = srcShortName.substr(0,classColStartIndex);
                var end = srcShortName.substr(classColEndIndex+1);
                
                var classNo = srcShortName.substring(classColStartIndex,classColEndIndex+1);
                
                //替换年级
                item.graduationName = this.formModel.graduation + "（" + classNo + "）班";
            });
        },
        handleGraduateClass(){
            this.$refs["queryForm"].validate(valid => {
                if (valid) {     
                    this.loading = true;

                    companyInfoApi.graduateClass(this.formModel.classList).then(response=>{
                        var jsonData = response.data;
                        this.loading = false;

                        if(jsonData.result){
                            this.$message.success("操作成功!");
                            this.$emit('update:visible', false);
                            this.$emit("success");
                        }
                        else{
                            this.$message.warning(jsonData.message + "");
                        }
                    });
                }
            });
        }
    }
}
</script>
<style>

</style>