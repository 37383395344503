<template>
    <el-dialog
        title="班级批量调整"
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="80%"
        @close="close"
    >
        <el-form ref="queryForm" :model="formModel" inline class="demo-form-inline">
            <el-form-item label="原上级单位" prop="srcParentId">
                <el-select-tree
                    size="mini"
                    :props="props"
                    :options="companyResult"
                    v-model="formModel.srcParentId"
                    height="200"
                ></el-select-tree>
            </el-form-item>
            <el-form-item label="新上级单位" prop="destParentId">
                <el-select-tree
                    size="mini"
                    :props="props"
                    :options="companyResult"
                    v-model="formModel.destParentId"
                    height="200"
                ></el-select-tree>
            </el-form-item>
            <el-form-item label="年级列号">
                <el-input
                    size="mini"
                    placeholder="起始列号,结束列号"
                    v-model="formModel.gradeColRange">
                </el-input>
            </el-form-item>
            <!-- <el-form-item label="班级列号">
                <el-input
                    size="mini"
                    placeholder="起始列号,结束列号"
                    v-model="formModel.classColRange">
                </el-input>
            </el-form-item> -->
            <el-form-item label="新年级号">
                <el-input
                    size="mini"
                    placeholder="新年级号"
                    v-model="formModel.newGradeNo">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="preview"
                >预览</el-button>
            </el-form-item>
            <el-table
            ref="formTable"
            :data="formModel.classList"
            stripe
            >
            <el-table-column prop="srcShortName" label="原班级简称" width="200"></el-table-column>
            <el-table-column prop="srcName" label="原班级全称" width="200"></el-table-column>
            <el-table-column prop="srcParentName" label="原上级单位" width="200"></el-table-column>
            <el-table-column prop="destShortName" label="新班级简称" width="200">
                <template slot-scope="scope">                    
                    <el-form-item                    
                        :prop="'classList.' + scope.$index + '.destShortName'"
                        :rules="ruleValidate['destShortName']"                    
                    >
                        <el-input
                            placeholder="新班级简称"
                            v-model="scope.row.destShortName">
                        </el-input>
                    </el-form-item>
                </template>
            </el-table-column>
            <el-table-column prop="destName" label="新班级全称" width="200">
                <template slot-scope="scope">                    
                    <el-form-item                    
                        :prop="'classList.' + scope.$index + '.destName'"
                        :rules="ruleValidate['destName']"                    
                    >
                        <el-input
                            placeholder="新班级全称"
                            v-model="scope.row.destName">
                        </el-input>
                    </el-form-item>
                </template>
            </el-table-column>
            <el-table-column prop="destParentName" label="新上级单位" width="200"></el-table-column>
        </el-table>
        </el-form>
        <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="handleUpgradeClass" :loading="loading">确定</el-button>
      </div>
    </el-dialog>
</template>
<script>
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";

export default {
    props: {
        "data" : {            
            type: Array
        },
        "parentId" : {
            type: String
        },
        "visible" :{
            type: Boolean
        },
        "companyResult" : {
            type: Array
        }
    },
    watch: {
        "data":function(newVal,oldVal){
            this.formModel.classList = newVal.map((item)=>{
                return {
                    id: item.id,
                    srcShortName: item.shortName,
                    srcName : item.name,
                    srcParentName: item.parentName,
                    destShortName: "",
                    destName: "",
                    destParentId: "",
                    destParentName: ""
                }
            });
        },
        "parentId":function(newVal,oldVal){
            this.formModel.srcParentId = newVal;
        },
        "visible":function(newVal,oldVal){
            this.dialogVisible = newVal;
        }
    },
    data() {
        return {
            dialogVisible: false,
            formModel: {
                srcParentId: "",
                destParentId :"",
                gradeColRange: "",
                classColRange: "",
                newGradeNo: "",
                classList: []
            },
            //companyResult: [],
            props: {
                // 配置项（必选）
                value: "id",
                label: "name",
                children: "children"
            },            
            ruleValidate: {
                destShortName: [{ required: true, message: "简称不能为空", trigger: "blur" }],
                destName: [{ required: true, message: "全称不能为空", trigger: "blur" }],
                destParentId: [{ required: true, message: "上级单位为空", trigger: "blur" }]
            },
            loading: false
        }
    },
    components: {
        "el-select-tree": SelectTree
    },
    created() {
        var self = this;

        // companyInfoApi.treeList().then(function(response) {
        //     var jsonData = response.data;
        //     if (jsonData.result) {
        //         self.companyResult = jsonData.data;
        //     }
        // });
    },
    methods:{
        close() {
            this.$emit('update:visible', false);
        },
        preview() {
            var gradeColRange = this.formModel.gradeColRange.split(",");
            // var classColRange = this.formModel.classColRange.split(",");

            var filterItems = this.companyResult.filter(item=>item.id ==this.formModel.destParentId);

            this.formModel.classList.forEach(item=>{
                var gradeColStartIndex = parseInt(gradeColRange[0])-1;
                var gradeColEndIndex = gradeColStartIndex;

                if(gradeColRange.length>1){
                    gradeColEndIndex = parseInt(gradeColRange[1])-1;
                }

                // var classColStartIndex = classColRange[0];
                // var classColEndIndex = classColStartIndex;

                // if(classColRange.length>1){
                //     classColEndIndex = classColRange[1];
                // }

                var srcShortName = item.srcShortName;
                var begin = srcShortName.substr(0,gradeColStartIndex);
                var end = srcShortName.substr(gradeColEndIndex+1);
                
                var gradeNo = srcShortName.substring(gradeColStartIndex,gradeColEndIndex+1);
                // var classNo = item.shortName.substring(classColStartIndex,classColEndIndex+1);

                //替换年级
                item.destShortName = begin + this.formModel.newGradeNo + end;
                item.destName = item.srcName.replace(item.srcShortName,item.destShortName);

                item.destParentId = this.formModel.destParentId;

                if(filterItems.length>0){
                    item.destParentName = filterItems[0].name;
                }
            });
        },        
        handleUpgradeClass(){
            this.$refs["queryForm"].validate(valid => {
                if (valid) {     
                    this.loading = true;

                    companyInfoApi.upgradeClass(this.formModel.classList).then(response=>{
                        var jsonData = response.data;
                        this.loading = false;

                        if(jsonData.result){
                            this.$message.success("操作成功!");
                            this.$emit('update:visible', false);
                            this.$emit("success");
                        }
                        else{
                            this.$message.warning(jsonData.message + "");
                        }
                    });
                }
            });
        }
    }
}
</script>
<style>

</style>