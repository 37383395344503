import request from '@/utils/request'
import constant from '@/constant'

function findByCompanyId(companyId){
    return request.get(constant.serverUrl + "/base/deviceTimeTemplate/findByCompanyId/" + companyId);
}

function batchSave(companyId,formModel){
    return request.post(constant.serverUrl + "/base/deviceTimeTemplate/batchSave/" + companyId, formModel,{
        headers: {
        "Content-Type": "application/json"
        }
    });
}

export default {
    findByCompanyId,batchSave
}