
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :append-to-body="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="教师" prop="personId">
          <el-select
          v-model="formModel.personId"
          filterable
          placeholder="请选择"
          style="width:150px"
        >
          <el-option
            v-for="item in personRsult"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="是否班主任" prop="isClassTeacher">
          <el-radio-group v-model="formModel.isClassTeacher">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="学科" prop="position">
          <el-input v-model="formModel.position" placeholder="请输入学科" style="width:300px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import personCompanyApi from "@/api/base/personCompany";
import personInfoApi from "@/api/base/personInfo";

export default {
  props: ["businessKey", "title","companyId","parentCompanyId"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
        isClassTeacher: [{ required: true, message: "请选择", trigger: "blur" }],
        position: [{ required: true, message: "不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      personRsult:[],
      disabledType:false,
    };
  },
  created() {
    var self = this;
    var formData = new FormData();
    formData.append("companyId",self.parentCompanyId);
    formData.append("popedom","3");
    personInfoApi.listByCompanyId(formData).then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.personRsult = jsonData.data;
        }
      }
    });
    this.loadTree();
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;
      self.formModel.companyId = self.companyId;
      
      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return personCompanyApi.add(self.formModel);
            } else {
              return personCompanyApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;

    (function() {
      if (self.businessKey.length == 0) {
        return personCompanyApi.create();
      } else {
        return personCompanyApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;
        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>