
<template>
    <div>
        <el-dialog v-drag-dialog :visible.sync="showDialog" :title="title" :modal-append-to-body="true" append-to-body
            style="text-align:left;" width="950px" :close-on-click-modal="false" @close="closeDialog">
            <div class="user-panel" v-loading="loading">
                <el-table class="compact-table" :data="formModel.timeTemplateList">
                    <el-table-column prop="deviceNo" label="关联设备" width="220">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.deviceNo" placeholder="请输入设备编号"
                            size="mini" style="width:200px;"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="timeTemplateIndex" label="时间模板序号" width="150">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.timeTemplateIndex" placeholder="请输入时间模板序号"
                            size="mini" style="width:140px;"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="beginTime" label="起始时间" width="200" >
                        <template slot-scope="scope">
                            <el-date-picker size="mini" :clearable="true" v-model="scope.row.beginTime"
                                style="width:180px;"
                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endTime" label="截止时间" width="200" >
                        <template slot-scope="scope">
                            <el-date-picker size="mini" :clearable="true" v-model="scope.row.endTime"
                            style="width:180px;"
                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100" fixed="right">
                        <template slot-scope="{row}">
                            <i class="el-icon-delete my-font" @click="handleDelete(row)"></i>
                            <i class="el-icon-document-add my-font" @click="handleInsert(row)"></i>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="el-table__empty-block">
                    <el-button icon="el-icon-circle-plus-outline" @click="handleAdd()">新增</el-button>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="handleSubmit" :loading="submitting">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import deviceTimeTemplateApi from "@/api/base/deviceTimeTemplate";

export default {
    props: ["companyId", "title"],
    data() {
        return {
            showDialog: true,
            loading: false,
            submitting: false,
            formModel: {
                timeTemplateList: [],
            }
        };
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        handleSubmit() {
            var self = this;

            deviceTimeTemplateApi.batchSave(self.companyId,self.formModel.timeTemplateList).then((response) => {
                var jsonData = response.data;

                if (jsonData.result) {
                    self.$message({
                        message: "保存成功!",
                        type: "success",
                    });

                    self.$emit("close", true);
                } else {
                    self.$message({
                        message: jsonData.message + "",
                        type: "warning",
                    });
                }
            });
        },
        handleAdd() {
            this.formModel.timeTemplateList.push({
                beginTime: "",
                endTime: "",
                deviceNo: "",
                timeTemplateIndex: "",
                companyId: this.companyId
            });
        },
        handleInsert(row) {
            var timeTemplateList = this.formModel.timeTemplateList;
            var index = timeTemplateList.indexOf(row);

            if (index >= 0) {
                timeTemplateList.splice(index + 1, 0, {
                    beginTime: "",
                    endTime: "",
                    deviceNo: "",
                    timeTemplateIndex: "",
                    companyId: this.companyId
                });
            }
        }
    },
    created() {
    },
    mounted: function () {
        var self = this;

        deviceTimeTemplateApi.findByCompanyId(self.companyId)
            .then((response) => {
                var jsonData = response.data;
                self.loading = false;

                if (jsonData.result) {
                    self.formModel.timeTemplateList = jsonData.data;
                } else {
                    self.$message.error(jsonData.message + "");
                }
            })
            .catch((error) => {
                self.$message.error(error + "");
            });
    }
};
</script>
  
<style rel="stylesheet/scss" lang="scss" scoped>
.user-panel {
    margin: 10px auto;
}

.input-form-main {
    width: 300px;
}

.link-span {
    margin-right: 20px;
}

.el-form-item {
    margin-bottom: 25px;
}

.my-font {
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
}

.user-panel {
    margin: 10px auto;
}

.compact-table {
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>